import { useState, useEffect, useCallback } from "react";
import Debug from "debug";

const Log = Debug("useCacheBuster");

export default function useCacheBuster() {
  const [state, setState] = useState({
    isLatestVersion: true,
    refreshCacheAndReload: () => {
      Log("Clearing cache and hard reloading...");
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
      }

      // delete browser cache and hard reload
      window.location.reload(true);
    }
  });

  // version from response - first param, local version second param
  function semverGreaterThan(versionA, versionB) {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  }

  function fetchMetaJson() {
    fetch("/meta.json")
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = process.env.REACT_APP_VERSION;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          Log(
            `We have a new version ${latestVersion} (you are running ${currentVersion}). Please reload.`
          );
          if (state.isLatestVersion !== false) {
            setState(prevState => {
              return { ...prevState, isLatestVersion: false };
            });
          }
        } else {
          Log(
            `You have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          if (state.isLatestVersion !== true) {
            setState(prevState => {
              return { ...prevState, isLatestVersion: true };
            });
          }
        }
      });
  }

  const memoFetchMetaJson = useCallback(fetchMetaJson, []);

  useEffect(() => {
    // This is the initial version that runs after 10 seconds
    const timeoutRef = setTimeout(memoFetchMetaJson, 10000);

    // This is the version that gets executed every 60 minutes
    const intervalRef = setInterval(memoFetchMetaJson, 60 * 60 * 1000);

    return () => {
      clearInterval(intervalRef);
      clearTimeout(timeoutRef);
    };
  }, [memoFetchMetaJson]);

  return [state.isLatestVersion, state.refreshCacheAndReload];
}
