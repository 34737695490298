import React, { useState, useEffect, useReducer } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Debug from "debug";

import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { useRoutes } from "hookrouter";
import { CookiesProvider, useCookies } from "react-cookie";
import useCacheBuster from "./components/useCacheBuster";
import LoadingSplashScreen from "./components/LoadingSplashScreen";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import { FIREBASE_CONFIG } from "./Config";

const HomePageLayout = React.lazy(() => import("./pages/home"));
const AppArea = React.lazy(() => import("./pages/app"));
const LinkWizard = React.lazy(() => import("./pages/home/Linker"));
const UnsubscribePage = React.lazy(() => import("./pages/home/Unsubscribe"));

const Log = Debug("OA_index");

const config = FIREBASE_CONFIG;

// Initialise debugging if we are on localhost development
if (process.env.NODE_ENV === "development" && !localStorage.getItem("debug"))
  localStorage.setItem("debug", "*"); // Doesnt yet exist, so create it.

if (!firebase.apps.length) {
  Log(`Initialize Firebase`);
  firebase.initializeApp(config);

  // This means that data from remote DB is stored in a local DB on supported browsers
  // which are Chrome, Safari and Firefox. This will enable "offline" access
  // as well as faster response times to queries that have previously been run.
  // For more info on persistence see:
  // https://firebase.google.com/docs/firestore/manage-data/enable-offline
  firebase
    .firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch(function(err) {
      if (err.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.log(
          "Multiple tabs open and persistence can only be enabled in one tab at a time."
        );
      } else if (err.code === "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.log(
          "Current browser does not support all of the features required to enable persistence"
        );
      }
    });
}

export const GlobalState = React.createContext({});
export const AuthContext = React.createContext({ user: null });

export const useAuth = () => {
  const [state, setState] = React.useState(() => {
    const user = firebase.auth().currentUser;
    return { initializingFirebase: !user, user };
  });

  function onChange(user) {
    setState({ initializingFirebase: false, user });
    if (user) {
      Log(`useAuth state changed uid: ${user.uid}`);
    } else {
      Log("useAuth state changed but no user id (user logged out)");
    }
  }

  React.useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return state;
};

const routes = {
  "/app*": () => (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      <AppArea />
    </React.Suspense>
  ),

  "/linkwiz": () => (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      <LinkWizard />
    </React.Suspense>
  ),

  "/linkwiz/:member": ({ member }) => (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      <LinkWizard member={member} />
    </React.Suspense>
  ),

  // unsubscribe no email or name
  "/unsubscribe/:emailID/:checksum": ({ emailID, checksum }) => (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      <UnsubscribePage emailID={emailID} checksum={checksum} email="" name="" />
    </React.Suspense>
  ),

  // unsubscribe with email and name
  "/unsubscribe/:emailID/:checksum/:email/:name": ({
    emailID,
    checksum,
    email,
    name
  }) => (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      <UnsubscribePage
        emailID={emailID}
        checksum={checksum}
        email={decodeURIComponent(email)}
        name={decodeURIComponent(name)}
      />
    </React.Suspense>
  ),

  "*": () => (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      <HomePageLayout />
    </React.Suspense>
  )
};

const Routes = () => {
  const routeResult = useRoutes(routes);
  return routeResult;
};

const App = () => {
  const [isUpdateAlertOpen, setIsUpdateAlertOpen] = useState(false);
  const { user, initializingFirebase } = useAuth();
  const cookies = useCookies(["oaUser"])[0];
  const removeCookie = useCookies(["oaUser"])[2];

  function reducer(state, action) {
    switch (action.type) {
      case "setMemberInfo":
        state.user.name = action.value.name;
        state.user.ylname = action.value.ylname;
        state.user.number = action.value.member;
        state.user.plan = action.value.plan;
        state.user.rank = action.value.rank;
        state.user.isActive = action.value.isActive;
        state.user.isAdmin =
          action.value.isAdmin && action.value.isAdmin === true ? true : false;

        // Prefill the FreshDesk Help Widget
        window.FreshworksWidget("identify", "ticketForm", {
          name: state.user.name,
          email: state.auth.email
        });

        return { ...state };

      case "setMemberName":
        state.user.name = action.value;
        return { ...state };

      case "setMemberYLName":
        state.user.ylname = action.value;
        return { ...state };

      case "setMemberNumber":
        state.user.number = action.value;
        return { ...state };

      case "setPlan":
        state.user.plan = action.value;
        return { ...state };

      case "setRank":
        state.user.rank = action.value;
        return { ...state };

      case "setIsActive":
        state.user.isActive = action.value;
        return { ...state };

      case "setIsAdmin":
        state.user.isAdmin = action.value;
        return { ...state };

      case "signin":
        state.isAuthenticated = true;
        state.logOutNow = false;
        state.auth = action.value;
        state.user.name = action.value.displayName;
        return { ...state };

      case "signout":
        firebase.auth().signOut();
        state.isAuthenticated = false;
        state.auth = null;
        state.isUser = false;
        state.user.isActive = false;
        state.user.name = "";
        state.user.number = 0;
        state.user.plan = 0;
        state.logOutNow = true;
        removeCookie("oaUser");
        Log("SIGNOUT");
        return { ...state };

      case "revalidate":
        state.user = action.value;
        // Prefill the FreshDesk Help Widget
        window.FreshworksWidget("identify", "ticketForm", {
          name: state.user.name,
          email: state.auth.email
        });
        return { ...state };

      case "versionChecked":
        state.lastVersionCheck = new Date();
        return { ...state };

      default:
        throw new Error("Unknown dispatch type");
    }
  }

  const initialState = {
    // An official user of our App
    user: {
      name: "",
      number: 0,
      plan: 0,
      rank: 0,
      isActive: false
    },
    isUser: false,

    // All of the info we get from the auth object when they login
    auth: {
      uid: "",
      displayName: ""
    },
    isAuthenticated: false,
    logOutNow: false,
    lastVersionCheck: new Date(new Date().setFullYear(2018))
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // See if we can set the initial user info from the oaUser cookie
  if (
    !initializingFirebase &&
    state.isAuthenticated === false &&
    cookies.oaUser &&
    user &&
    user.uid === cookies.oaUser.uid &&
    state.logOutNow === false
  ) {
    Log(`Using cookie for auth`);
    dispatch({ type: "signin", value: user });
    dispatch({ type: "revalidate", value: cookies.oaUser });
  }

  // Create the Query snapshotListener to listen for any actions that have occured
  // on any milestones.
  React.useEffect(() => {
    let milestoneActivityListener;

    // If we have a member logged in who is active, setup the query event listeners.
    if (
      state.user.isActive &&
      state.user.number > 0 &&
      typeof milestoneActivityListener !== "function"
    ) {
      Log(
        `isActive: ${state.user.isActive} Member: ${state.user.name} ${state.user.number}`
      );

      //
      // ANY NEW MILESTONE ACTIVITY FOR THIS USER?
      milestoneActivityListener = firebase
        .firestore()
        .collection("milestones")
        .where("assignedUsers", "array-contains", state.user.number)
        .orderBy("actions.updatedAt", "desc")
        .limit(1)
        .onSnapshot(
          querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
              let docLastActionDate = change.doc
                .data()
                .actions.updatedAt.toDate()
                .getTime();

              // If the oa_last_action sessionStorage item does not yet exist,
              // then this means it is the first time we have run and we need to
              // use this result to initialise oa_last_action
              let storedLastActionDate = docLastActionDate;
              if (sessionStorage.getItem("oa_last_action")) {
                storedLastActionDate = sessionStorage.getItem("oa_last_action");
              } else {
                // The oa_last_action does not exist yet, so set it.
                try {
                  sessionStorage.setItem("oa_last_action", docLastActionDate);
                } catch (e) {
                  console.error(`sessionStorage.setItem error : ${e}`);
                }
              }

              // We keep an eye on the date that the most recently actioned milestone
              // has in its actions.updateAt field. If this changes, then we know
              // an action has occured on one of their milestones.

              if (
                parseInt(storedLastActionDate) !== parseInt(docLastActionDate)
              ) {
                // There has been an update.
                try {
                  sessionStorage.setItem("oa_last_action", docLastActionDate);
                  sessionStorage.setItem(
                    "oa_updated_at",
                    new Date().getTime() - 1
                  );

                  // Push this milestone onto the notification stack for notistack
                  // to display which occurs in app/AppFooter.js
                  // We only do notifcations for primary milestones
                  if (change.doc.data().primaryMilestone === true) {
                    // A primary milestone
                    Log(
                      `Milestone actions on primary milestone: ${change.doc.id}`
                    );

                    // We add info about this milestone to the notificationStack
                    let notificationStack = [];
                    if (sessionStorage.getItem("notification_stack")) {
                      notificationStack = JSON.parse(
                        sessionStorage.getItem("notification_stack")
                      );
                    }

                    // We limit the size of the notificationStack to 5 notifications
                    // Once we hit out limit, remove the oldest notification before adding on
                    // the newest one.
                    if (notificationStack.length > 4)
                      notificationStack = notificationStack.slice(1);

                    notificationStack.push({
                      actions: change.doc.data().actions,
                      memberName: change.doc.data().memberName,
                      member: change.doc.data().member,
                      typeid: change.doc.data().typeid,
                      value: change.doc.data().value,
                      date: change.doc
                        .data()
                        .processingDate.toDate()
                        .getTime()
                    });
                    sessionStorage.setItem(
                      "notification_stack",
                      JSON.stringify(notificationStack)
                    );
                  } else {
                    // An other milestone - ie not a primary milestone.
                    // We do not send through a notification for non primary milestones
                    Log(
                      `Milestone actions on other (non-primary) milestone: ${change.doc.id}`
                    );
                  }

                  // We will go through all of the sessionStorage data (keys).
                  // If we find this milestone in that data, then we will update
                  // the actions field . This will keep our cached data up-to-date.

                  for (let x = 0; x < Object.keys(sessionStorage).length; x++) {
                    let key = Object.keys(sessionStorage)[x];

                    if (
                      (key.startsWith("dash_") || key.startsWith("/app/")) &&
                      !key.endsWith("_updatedAt") &&
                      !key.endsWith("_startAfter") &&
                      !key.startsWith("dash_shining") // This is totally different formatted data
                    ) {
                      let milestones = JSON.parse(sessionStorage.getItem(key));
                      let hasUpdatedMilestones = false;
                      for (let i = 0; i < milestones.length; i++) {
                        if (
                          milestones[i].id &&
                          milestones[i].id === change.doc.id
                        ) {
                          Log(
                            `Updating ${change.doc.id} in ${key} sessionStorage object`
                          );
                          milestones[i].actions = change.doc.data().actions;
                          hasUpdatedMilestones = true;
                        }
                      }
                      if (hasUpdatedMilestones)
                        sessionStorage.setItem(key, JSON.stringify(milestones));

                      // Update the _updatedAt value for this key
                      // but NOT for our Dashboard "Actioned Milestones" or "Actioned Other"
                      // or for /app/recent/ listing. We let them forcibly refresh.
                      if (
                        !key.startsWith("/app/recent/") &&
                        !key.endsWith("_actionedMilestones") &&
                        !key.endsWith("_actionedOther")
                      ) {
                        sessionStorage.setItem(
                          `${key}_updatedAt`,
                          new Date().getTime()
                        );
                      }
                    }
                  }
                } catch (e) {
                  console.error(`sessionStorage.setItem error : ${e}`);
                }
              }
            });
          },
          err => {
            console.error(`Failed to setup milestoneActivityListener : ${err}`);
          }
        );
    }

    return () => {
      // Remove the snapshot listeners when we unmount this component.
      if (typeof milestoneActivityListener === "function") {
        Log(`UNMOUNTED and removing snapshot listener`);
        milestoneActivityListener();
      }
    };
  }, [state.user.isActive, state.user.number, state.user.name]);

  const handleClose = () => {
    setIsUpdateAlertOpen(false);
  };

  //
  // Cache Busting Code!
  //
  // We use our custom hook, useCacheBuster to make sure that the client
  // regularily checks to ensure that they are running the latest version
  // available.
  //
  const [isLatestVersion, refreshCacheAndReload] = useCacheBuster();

  useEffect(() => {
    if (!isLatestVersion) {
      Log(`Refresh Cache and Reload please!`);
      setIsUpdateAlertOpen(true);
    }
  }, [isLatestVersion]);

  useEffect(() => {
    Log(`/index.js MOUNTED`);

    return () => {
      Log(`/index.js UNMOUNTED`);
    };
  }, []);

  if (initializingFirebase) {
    Log(`Initializing Firebase - displaying splash screen until initialised.`);
    return <LoadingSplashScreen />;
  }

  return (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      <AuthContext.Provider value={{ user }}>
        <GlobalState.Provider value={{ state, dispatch }}>
          <Routes />

          <Dialog
            open={isUpdateAlertOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"New version available - Reload Now"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                An update of Oily Achievements has been released, please tap the
                OK button to reload the latest version.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Ignore
              </Button>
              <Button onClick={refreshCacheAndReload} color="primary" autoFocus>
                OK Reload Now
              </Button>
            </DialogActions>
          </Dialog>
        </GlobalState.Provider>
      </AuthContext.Provider>
    </React.Suspense>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  rootElement
);

// Learn more about service workers: https://bit.ly/CRA-PWA
// Service workers will get registered on Staging and Production but NOT on Development
if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_CONFIG !== "playground"
) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
