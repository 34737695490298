import React from "react";
import PropTypes from "prop-types";

import { Container, CircularProgress, Grid, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  circle: {
    top: "-215px",
    position: "relative"
  },

  container: {
    "&:hover": {
      cursor: "progress"
    }
  }
});

export default function LoadingSplashScreen({ visibility }) {
  const classes = useStyles();

  if (visibility && visibility === "hidden") return null;

  return (
    <Container maxWidth="sm" classes={{ root: classes.container }}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <br />
          <br />
          <img
            src="/icons/android-icon-192x192.png"
            alt="Loading"
            width="192"
            height="192"
          />
        </Grid>
        <Grid item>
          <br />
          <br />
          <Fade in={true} timeout={{ enter: 1500, exit: 0 }}>
            <CircularProgress
              size={115}
              thickness={4.2}
              classes={{ root: classes.circle }}
            />
          </Fade>
        </Grid>
      </Grid>
    </Container>
  );
}

LoadingSplashScreen.propTypes = {
  visibility: PropTypes.string
};

LoadingSplashScreen.defaultProps = {
  visibility: "visible"
};
