// We always default to the playground environment if we do not get a match on the hostname
let envName = process.env.REACT_APP_CONFIG || "playground";

let FIREBASE = {};

if (envName === "playground") {
  FIREBASE = {
    apiKey: "AIzaSyBcYgZRuONVUcrcG91GvYgqoaEu9dJ2p3o",
    authDomain: "playground-246211.firebaseapp.com",
    databaseURL: "https://playground-246211.firebaseio.com",
    projectId: "playground-246211",
    storageBucket: "playground-246211.appspot.com",
    messagingSenderId: "974062917811",
    appId: "1:974062917811:web:e9266faff5520c07"
  };
}

if (envName === "production") {
  FIREBASE = {
    apiKey: "AIzaSyBP7oUveVK7cL6QfghBi2v8N2Eprvs5lF4",
    authDomain: "oily-a.firebaseapp.com",
    databaseURL: "https://oily-a.firebaseio.com",
    projectId: "oily-a",
    storageBucket: "oily-a.appspot.com",
    messagingSenderId: "80831547698",
    appId: "1:80831547698:web:889cf4e789de516fde6f2b",
    measurementId: "G-LNQFETJNRX"
  };
}

export const FIREBASE_CONFIG = FIREBASE;
